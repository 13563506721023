const GET_SEARCH_EXERCISES = "/food/getSearchExercises";


const getSearchExercisesAction = (exercises) => ({
    type: GET_SEARCH_EXERCISES,
    exercises,
});




export const getSearchExercises = (searchTerm) => async (dispatch) => {
    const res = await fetch(`/api/exercise`, {
        method: "GET",
    });

    if (res.ok) {
        const exercises = await res.json();
        dispatch(getSearchExercisesAction(exercises));
        return exercises;
    }
};

const initialState = {};

export const searchExercisesReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case GET_SEARCH_EXERCISES: {
            newState = { ...action.exercises }
            return newState;
        }

        default:
            return state;
    }

};
