export const showNutrientDetail = (id, carbGoal, fatGoal, proteinGoal) => {
    const nutrientDetails = {
        221: { name: 'Alcohol', unit: 'g', target: 0 },
        262: { name: 'Caffeine', unit: 'mg', target: 0 },
        255: { name: 'Water', unit: 'g', target: 3700 },
        291: { name: 'Fiber', unit: 'g', target: 38 },
        209: { name: 'Starch', unit: 'g', target: 0 },
        269: { name: 'Sugars', unit: 'g', target: 0 },
        205: { name: 'Net carbs', unit: 'g', target: carbGoal },
        204: { name: 'Total fat', unit: 'g', target: fatGoal },
        645: { name: 'Monounsaturated fat', unit: 'g', target: 0 },
        646: { name: 'Polyunsaturated fat', unit: 'g', target: 0 },
        619: { name: 'Alphalinolenic acid', unit: 'g', target: 2 },
        629: { name: 'Eicosapentaenoic acid', unit: 'g', target: 0.25 },
        621: { name: 'Docosahexaenoic acid', unit: 'g', target: 0.25 },
        618: { name: 'Linoleic acid', unit: 'g', target: 10 },
        620: { name: 'Arachidonic acid', unit: 'g', target: 7 },
        606: { name: 'Saturated fat', unit: 'g', target: 0 },
        605: { name: 'Trans fat', unit: 'g', target: 0 },
        601: { name: 'Cholesterol', unit: 'mg', target: 0 },
        203: { name: 'Total protein', unit: 'g', target: proteinGoal },
        512: { name: 'Histidine', unit: 'g', target: 1.1 },
        503: { name: 'Isoleucine', unit: 'g', target: 1.5 },
        504: { name: 'Leucine', unit: 'g', target: 3.3 },
        505: { name: 'Lysine', unit: 'g', target: 3 },
        506: { name: 'Methionine', unit: 'g', target: 0.8 },
        508: { name: 'Phenylalanine', unit: 'g', target: 1.3 },
        502: { name: 'Threonine', unit: 'g', target: 1.6 },
        501: { name: 'Tryptophan', unit: 'g', target: 0.4 },
        510: { name: 'Valine', unit: 'g', target: 1.9 },
        404: { name: 'Vitamin b1', unit: 'mg', target: 1.2 },
        405: { name: 'Vitamin b2', unit: 'mg', target: 1.3 },
        406: { name: 'Vitamin b3', unit: 'mg', target: 16 },
        410: { name: 'Vitamin b5', unit: 'mg', target: 5 },
        415: { name: 'Vitamin b6', unit: 'mg', target: 1.3 },
        418: { name: 'Vitamin b12', unit: 'Aug', target: 2.4 },
        417: { name: 'Folate', unit: 'Aug', target: 400 },
        320: { name: 'Vitamin a', unit: 'Aug', target: 900 },
        401: { name: 'Vitamin c', unit: 'mg', target: 90 },
        324: { name: 'Vitamin d', unit: 'iu', target: 600 },
        323: { name: 'Vitamin e', unit: 'mg', target: 15 },
        430: { name: 'Vitamin k', unit: 'Aug', target: 120 },
        301: { name: 'Calcium', unit: 'mg', target: 1000 },
        312: { name: 'Copper', unit: 'mg', target: 0.9 },
        303: { name: 'Iron', unit: 'mg', target: 8 },
        304: { name: 'Magnesium', unit: 'mg', target: 400 },
        315: { name: 'Manganese', unit: 'mg', target: 2.3 },
        305: { name: 'Phosphorus', unit: 'mg', target: 700 },
        306: { name: 'Potassium', unit: 'mg', target: 3400 },
        317: { name: 'Selenium', unit: 'Aug', target: 55 },
        307: { name: 'Sodium', unit: 'mg', target: 1500 },
        309: { name: 'Zinc', unit: 'mg', target: 11 },
    };

    return nutrientDetails[id] || { name: 'none', unit: 'none', target: 0 };
};
